<template>
    <div>
        <vx-card class="tabulator_card">
            <vs-row>
                <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="card2 no-padd mb-3">
                    <p style="text-align:center">
                        <span v-if="city_calssification != null">GM Stats - {{ city_calssification }} - &nbsp;</span><span v-else>Session City Stats - {{ session_city }} - &nbsp;</span>{{ giveSelectedStat(selected_stats) }}
                    </p>
                </vs-col>
                <div ref="table3" id="example-table-theme"></div>
            </vs-row>
        </vx-card>
    </div>
</template>
  
<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Treeselect from "@riophae/vue-treeselect";
import EventBus from "../components/eventbus";
import moment from "moment";
export default {
    components: {
        Treeselect,
    },
    props: ["table_details", "selected_stats", "city_calssification", "session_city"],
    data() {
        return {
            selected_column: [],
            treeDataHide: [
                {
                    id: "company",
                    label: "Company"
                },
                {
                    id: "education",
                    label: "Education"
                },
                {
                    id: "level",
                    label: "Level"
                },
                {
                    id: "course",
                    label: "Course"
                },
                {
                    id: "batch",
                    label: "Batch"
                },
                {
                    id: "enrollment_date",
                    label: "Enrollment Date"
                },
                {
                    id: "next_call",
                    label: "Next Call Date"
                },
                {
                    id: "last_call",
                    label: "Last Call Date"
                },
                {
                    id: "assigned_to_name",
                    label: "Enrolled By Spoc"
                },
                {
                    id: "spoc_name",
                    label: "CM SPOC"
                },
                {
                    id: "sr_name",
                    label: "SR SPOC"
                },
                {
                    id: "city",
                    label: "City"
                },
                {
                    id: "city_classification",
                    label: "City Classification"
                },
                {
                    id: "gm_name",
                    label: "GM"
                },
                {
                    id: "batch_start_date",
                    label: "Batch Start Date"
                },
                {
                    id: "created_at",
                    label: "Lead Created Date"
                },
                {
                    id: "actual_cost",
                    label: "Actual Cost"
                },
                {
                    id: "agreed_cost",
                    label: "Agreed Cost"
                },
                {
                    id: "discount_amount",
                    label: "Discount Amount"
                },
                {
                    id: "reason_for_discount",
                    label: "Comments"
                },
                {
                    id: "approved_by",
                    label: "Approved By",
                },
            ],
            // tabulatorDetails: [
            //     {
            //         "id": 1,
            //         "mwb_id": 2317328,
            //         "person_name": "Samantha Miles",
            //         "feedback": "Not satisfied",
            //         "identity": "B-680025",
            //         "feedback_date": "2023-01-20 10:11:27",
            //         "spoken_to_id": 1194,
            //         "spoken_to_name": "Chaitali Mondal",
            //         "spoc_manager_name": "Roopa Nagaraju",
            //         "spoc_manager_id": 93,
            //         "spoc_id": 2547,
            //         "spoc_name": "X-BAN Marketplace",
            //         "level": "M1",
            //         "iiml_level": null,
            //         "sr_id": null,
            //         "sr_name": null
            //     }
            // ],
        }
    },
    created(){
    EventBus.$on("close-download-popup", () => {
      this.$vs.loading.close(this.$refs.loadableButton.$el)
    });
  },
    mounted() {
        this.getEnrollmentDetails();
        for (let i = 0; i < this.table_details.length; i++) {
            const element = this.table_details[i];
            if (element.batch_start_date != null) {
                element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
            }
            if (element.enrollment_date != null) {
                element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
            }
            if (element.last_call !== null) {
                element.last_call = moment
                    .unix(element.last_call)
                    .format("DD-MMM-YYYY");
            }
            if (element.next_call !== null) {
                element.next_call = moment
                    .unix(element.next_call)
                    .format("DD-MMM-YYYY");
            }
            let image_path = "";
            if (this.ActiveNavBarTab == "approved") {
                image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
            } else if (this.ActiveNavBarTab == "rejected") {
                image_path = "https://cdn-icons-png.flaticon.com/512/5978/5978441.png"
            } else {
                image_path = "https://img.icons8.com/color/24/000000/error.png"
            }
            Object.assign(element, { approved: image_path });
            Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
        }
    },
    watch: {
        table_details(val) {
            this.table_details = val;
            this.getEnrollmentDetails();
            for (let i = 0; i < this.table_details.length; i++) {
                const element = this.table_details[i];
                // if (element.enrollment_date != null) {
                //   element.enrollment_date = moment.unix(element.enrollment_date)
                //     .format("DD-MMM-YYYY");
                // }
                if (element.created_date != null) {
                    element.created_date = moment(element.created_date).format("DD-MMM-YYYY");
                }
                if (element.batch_start_date != null) {
                    element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
                }
                if (element.enrollment_date != null) {
                    element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
                }
                if (element.last_call !== null) {
                    element.last_call = moment
                        .unix(element.last_call)
                        .format("DD-MMM-YYYY");
                }
                if (element.next_call !== null) {
                    element.next_call = moment
                        .unix(element.next_call)
                        .format("DD-MMM-YYYY");
                }
                let image_path = "";
                if (this.ActiveNavBarTab == "approved") {
                    image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
                } else if (this.ActiveNavBarTab == "rejected") {
                    image_path = "https://cdn-icons-png.flaticon.com/512/5978/5978441.png"
                } else {
                    image_path = "https://img.icons8.com/color/24/000000/error.png"
                }
                Object.assign(element, { approved: image_path });
                Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
            }
        },
        ActiveNavBarTab(val) {
            this.ActiveNavBarTab = val;
            console.log("ActiveNavBarTab", this.ActiveNavBarTab);
        }
    },
    methods: {
        giveSelectedStat(val) {
            console.log('giveSelectedStat', val);
            if(val == 'total'){
                return 'Total';
            }
            if(val == 'total_enrolled'){
                return 'Total Enrolled';
            }
            if(val == 'total_non_enrolled'){
                return 'Total Non Enrolled';
            }
            if(val == 'lms_total_enrolled'){
                return 'Total Enrolled LMS';
            }
            if(val == 'mf_total_enrolled'){
                return 'Total Enrolled MF';
            }
            if(val == 'website_total_enrolled'){
                return 'Total Enrolled Website';
            }
            if(val == 'whatsApp_total_enrolled'){
                return 'Total Enrolled WhatsApp';
            }
            if(val == 'google_total_enrolled'){
                return 'Google Total Enrolled';
            }
            if(val == 'superbot_total_enrolled'){
                return 'SuperBot Total Enrolled';
            }
            if(val == 'others_total_enrolled'){
                return 'Others Total Enrolled';
            }
            if(val == 'lms_total_non_enrolled'){
                return 'Total Non Enrolled LMS';
            }
            if(val == 'mf_total_non_enrolled'){
                return 'Total Non Enrolled MF';
            }
            if(val == 'website_total_non_enrolled'){
                return 'Total Non Enrolled Website';
            }
            if(val == 'whatsApp_total_non_enrolled'){
                return 'Total Non Enrolled WhatsApp';
            }
            if(val == 'google_total_non_enrolled'){
                return 'Google Total Non Enrolled';
            }
            if(val == 'superbot_total_non_enrolled'){
                return 'SuperBot Total Non Enrolled';
            }
            if(val == 'others_total_non_enrolled'){
                return 'Others Total Non Enrolled';
            }
        },
        getEnrollmentDetails() {
            this.tabulator = new Tabulator(this.$refs.table3, {
                maxHeight: "80vh",
                // layout: "fitColumns",
                data: this.table_details,
                rowFormatter: (function (row) {
                    var evenColor = "#F5F6FA";
                    var oddColor = "#FFF";
                    row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
                }),
                columns: [
                    {
                        title: "Can-ID",
                        field: "identity",
                        frozen: true,
                        sorter: "alphanum",
                    },
                    {
                        title: "Candidate Name",
                        field: "person_name",
                        frozen: true,
                    },
                    {
                        title: "SPOC",
                        field: "spoc_name",
                    },
                    // {
                    //     title: "City",
                    //     field: "session_city",
                    // },
                    {
                        title: "GM",
                        field: "gm",
                    },
                    {
                        title: "Source",
                        field: "source",
                    },
                    {
                        title: "Enrollment Status",
                        field: "enrollment_status",
                    },
                    {
                        title: "Last Call",
                        field: "last_call",
                    },
                    {
                        title: "Next Call",
                        field: "next_call",
                    },
                    {
                        title: "Enrollment Date",
                        field: "enrollment_date",
                    },
                    {
                        title: "Course",
                        field: "courses",
                    },
                    {
                        title: "Registered Course",
                        field: "course",
                    },
                    {
                        title: "Registered Date",
                        field: "created_date",
                    },
                    {
                        title: "M Level",
                        field: "level",
                    },
                    {
                        title: "U Level",
                        field: "iiml_level",
                    },
                    {
                        title: "About",
                        field: "info",
                        frozen: true,
                        headerSort: false,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.getId,
                    },
                ],
            });
        },
        getId(e, cell) {
            console.log(e, cell);
            this.openBigPopup(cell._cell.row.data.mwb_id);
        },
        openEnrollmentApproval(e, cell) {
            console.log(e, cell._cell.row.data.mwb_id);
            EventBus.$emit("openEmrollmentApprovalPopup", cell._cell.row.data);
        },
        HideColumn() {
            this.treeDataHide.forEach((treeData) => {
                this.tabulator.showColumn(treeData.id)
            });
            if (this.selected_column.length > 0) {
                this.selected_column.forEach((selected_item) => {
                    this.treeDataHide.forEach((item) => {
                        if (item.id == selected_item) {
                            this.tabulator.hideColumn(item.id)
                        }
                    })
                })
            }
        },
    }
};
</script>
  
<style>
.tabulator_card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.tabulator {
    background-color: #f2f2f2;
}

.tabulator .tabulator-header {
    background-color: #f2f2f2;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
    background-color: #f2f2f2;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
    background-color: #e4e1e1;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
    text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
    background-color: rgb(0, 0, 0);
    color: #f2f2f2 !important;
}

.vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: 0;
}
.card2 p {
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    padding-inline: 20px;
    align-items: center;
    background-color: #6d7ae0;
    border-radius: 0 0 15px 15px;
    width: 52%;
    height: 35px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}
</style>