<template>
    <div>
        <div class="accelerator_search_container">
            <vs-row vs-w="12">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
                    <div class="enrollment_count">
                        <p class="accelrator_text">Accelerator Session</p>
                    </div>
                </vs-col>
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" class="no-padd">

                    <vs-row class="mb-5 mt-6" vs-w="12">
                        <vs-col vs-w="1.1" vs-offset="0.1" vs-type="flex" vs-justify="flex-start" vs-align="center">
                            <h6 class="ele-side-head filter">Filters</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5" class="accelrator-tree-drop"
                            id="webinar">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent>
                                    Webinar
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <v-select class="webinarSelect" v-model="selected_webinar" :options="webinars"></v-select>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="accelrator-tree-drop"
                            id="team">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent>
                                    Team
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect :disabled="disabled" v-model="initSelectedTeams" :multiple="true" :options="treeDataTeams"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="accelrator-tree-drop"
                            id="spoc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent>
                                    SPOC
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedSpoc" :multiple="true" :options="treeDataSpoc"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="accelrator-tree-drop"
                            id="mlevel">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent>
                                    CM Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        
                        <!-- <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="tree-drop"
                            id="date">
                            <datepicker placeholder="Registration Date" :minimumView="'month'" :maximumView="'month'"
                                class="my_date_picker" :format="customFormatter" v-model="selected_date"></datepicker>
                        </vs-col> -->
                        
                    </vs-row>
                </vs-col>
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" class="no-padd">
                    <vs-row class="mb-5 mt-6" vs-w="12">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="1.2" class="accelrator-tree-drop"
                            id="ulevel">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent>
                                    USP Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="init_selected_iiml_levels" :multiple="true" :options="macc_levels"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="accelrator-tree-drop"
                            id="course">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent>
                                    Course
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedCourses" :multiple="true" :options="treeDataCourses"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="accelrator-tree-drop "
                            id="attended">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text py-1" href.prevent>
                                    Attended
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initAttended" :multiple="true" :options="treeDataAttended" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" class="no-padd">
                    <vs-row class="mb-5 mt-6" vs-w="12">
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-offset="1.2" vs-w="2.5" class="mt-2">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Registration month and year</label>
                                <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'"
                                    class="macc_date_picker" :format="customFormatter" v-model="selected_date"></datepicker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" class="none_padding mt-2" vs-align="center" vs-w="2.5" > 
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Registration date range</label>
                                <date-range-picker ref="picker" :opens="'left'" :locale-data="{
                                    firstDay: 1,
                                    format: 'DD-MMM-YYYY',
                                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :ranges="ranges" :timePicker24Hour="true" :showWeekNumbers="false"
                                    :showDropdowns="false" :autoApply="false" v-model="registrationDateRange"
                                    class="register_date">
                                </date-range-picker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2.5" class="mt-2">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Accelerator month and year</label>
                                <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'"
                                    class="macc_date_picker" :format="customFormatter" v-model="selected_accelerator_date"></datepicker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.5"
                            style="margin-top: 8px;">
                            <div>
                                <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <vs-row class="my-6">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="11.7">
                    <vs-row class="criteria">
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="11.7">
                                <h6 class="criteria_heading">Search Criteria:</h6>
                            </vs-col>
                        </vs-row>
                        <vs-row vs-w="12">
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1"
                                vs-w="10.7">
                                <div style="display: block; width: 100%">
                                    <span>
                                        <vs-chip v-if="remove_webinar != ''"
                                            @click="removechip(remove_webinar, 'webinar')" class="criteria_chip"
                                            closable color="#f1ad78">
                                            <b>{{ remove_webinar }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_teams" :key="chip">
                                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                            @click="removechip(chip, 'team')" color="#caecc5">
                                            <b v-if="chip == 'ExEd'"> USP </b>
                                            <b v-else>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_spoc" :key="chip">
                                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                            @click="removechip(chip, 'spoc')" color="#d0f1fb">
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_levels" :key="chip">
                                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                            @click="removechip(chip, 'mlevel')" color="#d0f1fb">
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_iiml_levels" :key="chip">
                                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                            @click="removechip(chip, 'iiml_level')" color="#edebf3">
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_courses" :key="chip">
                                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                            @click="removechip(chip, 'course')" color="#f1ad78">
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_attended" :key="chip">
                                        <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                            @click="removechip(chip, 'attended')" color="#eae5fa">
                                            <!-- <b v-if="chip == 1">Yes</b>
                                            <b v-else-if="chip == 0">No</b>
                                            <b v-else>All</b> -->
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                </div>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                                <vs-button color="#0044ba" icon="search" size="small" @click="getStats()"></vs-button>
                                &nbsp;
                                <vs-button color="#0044ba" icon="refresh" size="small"
                                    @click="refreshBdeLeadsData()"></vs-button>
                            </vs-col>
                        </vs-row>
                    </vs-row>
                </vs-col>
            </vs-row>
        </div>

        <vx-card class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
            <vs-row vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="10" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-6">
                            <p style="text-align:center; margin-left: 19%;">
                                GM Stats
                            </p>
                        </vs-col>
                        <vs-col vs-w="2" class="mt-2"  vs-type="flex" vs-justify="flex-end">
                            <vs-button
                            color="#0044BA"
                            icon="file_download"
                            size="small"
                            @click="downloadReport()"
                            ref="loadableButton"
                            id="button-with-loading"
                            class="vs-con-loading__container mb-2"
                            style="height: 40px"
                            >Download</vs-button
                            >
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center">
                            <div class="fixTableHead">
                                <table class="rwd-table" id="levels-stats">
                                    <thead>
                                        <tr>
                                            <th>City</th>
                                            <th>Total</th>
                                            <th>Total Enrolled</th>
                                            <th>Total Non Enrolled</th>
                                            <th>Total Enrolled LMS</th>
                                            <th>Total Enrolled MF</th>
                                            <th>Total Enrolled Website</th>
                                            <th>Total Enrolled Whatsapp</th>
                                            <th>Total Enrolled Google</th>
                                            <th>Total Enrolled SuperBot</th>
                                            <th>Others Total Enrolled</th>
                                            <th>Total Non Enrolled LMS</th>
                                            <th>Total Non Enrolled MF</th>
                                            <th>Total Non Enrolled Website</th>
                                            <th>Total Non Enrolled Whatsapp</th>
                                            <th>Total Non Enrolled Google</th>
                                            <th>Total Non Enrolled SuperBot</th>
                                            <th>Others Total Non Enrolled</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbl-content">
                                        <tr v-for="item in gm_city_stats" :key="item.city">
                                            <td>{{ item.city }}</td>
                                            <td id="stats_total" v-for="t_col in table_columns" :key="t_col" @click="getTabulatorDeatilsPage(item.city, t_col)">
                                                {{ item[t_col] }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td v-if="gm_city_stats.length != 0">Total</td>
                                            <td v-for="(val, key) in total_of_gm_stats" :key="key">{{ val }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>

        <vx-card class="gm-stats-filter-card" style="margin:2% 0 2% 0;padding-bottom:4%">
            <vs-row vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
                            <p style="text-align:center">
                                Session City Stats
                            </p>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center">
                            <div class="fixTableHead">
                                <table class="rwd-table" id="levels-stats">
                                    <thead>
                                        <tr>
                                            <th>City</th>
                                            <th>Total</th>
                                            <th>Total Enrolled</th>
                                            <th>Total Non Enrolled</th>
                                            <th>Total Enrolled LMS</th>
                                            <th>Total Enrolled MF</th>
                                            <th>Total Enrolled Website</th>
                                            <th>Total Enrolled Whatsapp</th>
                                            <th>Total Enrolled Google</th>
                                            <th>Total Enrolled SuperBot</th>
                                            <th>Others Total Enrolled</th>
                                            <th>Total Non Enrolled LMS</th>
                                            <th>Total Non Enrolled MF</th>
                                            <th>Total Non Enrolled Website</th>
                                            <th>Total Non Enrolled Whatsapp</th>
                                            <th>Total Non Enrolled Google</th>
                                            <th>Total Non Enrolled SuperBot</th>
                                            <th>Others Total Non Enrolled</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbl-content">
                                        <tr v-for="item in session_city_stats" :key="item.city">
                                            <td>{{ item.city }}</td>
                                            <td id="stats_total" v-for="t_col in table_columns" :key="t_col" @click="getTabulatorDeatilsPage(item.city, t_col)">
                                                {{ item[t_col] }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td v-if="session_city_stats.length != 0">Total</td>
                                            <td v-for="(val, key) in total_of_session_city_stats" :key="key">{{ val }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
        </vx-card>
        <div v-if="tabulator_details.length != 0">
            <AcceleratorSessionTabulator :session_city="session_city" :city_calssification="city_calssification" :selected_stats="selected_stats" :table_details="tabulator_details"></AcceleratorSessionTabulator>
        <vs-row style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
                <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
            </vs-col>
        </vs-row>
        </div>
        <vs-popup class="holamundo download-report" title="" :active.sync="download_report_success_popup">
      <div class="popup-box">
        <h5>Download Success <vs-icon icon="schedule_send" size="22px" color="#91C11E" style="margin-left:12px"></vs-icon>
        </h5>
        <p>Accelerator Session Report will be sent to your mail shortly</p>
      </div>
    </vs-popup>
    </div>
</template>
<script>
import eventbus from '../components/eventbus'
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import AcceleratorSessionTabulator from "./AcceleratorSessionTabulator.vue";
export default {
    components: {
    vSelect,
    Treeselect,
    Datepicker,
    DateRangePicker,
    AcceleratorSessionTabulator
},
    data: () => ({
        download_report_success_popup:false,
        webinars: [],
        selected_webinar: null,
        remove_webinar: "",
        webinar_date: '',
        live_webinar_id: '',
        spoc_ids_data: '',
        selected_stats: '',
        currentTablePage: 1,
        tablelinks: 0,
        tabulator_details: [],
        registrationDateRange: {
            startDate: null,
            endDate: null,
        },
        selected_date: null,
        selected_accelerator_date: null,
        initSelectedCourses: [],
        initSelectedTeams: [],
        initSelectedSpoc: [],
        initSelectedLevels: [],
        init_selected_iiml_levels: [],
        selected_courses: [],
        selected_teams: [],
        selected_spoc: [],
        selected_levels: [],
        selected_iiml_levels: [],
        initAttended: ["All"],
        selected_attended: [],
        total_of_gm_stats: {},
        total_of_session_city_stats: {},
        treeDataCourses: [
            {
                id: "All",
                label: "All",
                children: [
                    {
                        id: "CPA",
                        label: "CPA",
                    },
                    {
                        id: "CMA",
                        label: "CMA",
                    },
                    {
                        id: "USP",
                        label: "USP",
                    },
                ]
            }],
        treeDataTeams: [
        {
            id: "All",
            label: "All",
            children: [
                {
                    id: "CM",
                    label: "CM",
                },
                {
                    id: "SR",
                    label: "SR",
                },
                {
                    id: "ExEd",
                    label: "USP",
                },
            ]
        }],
        treeDataSpoc: [
            {
            id: "All",
            label: "All",
            children: [],
            },
        ],
        treeDataLevels: [
            {
            id: "All",
            label: "All",
            children: [
                {
                id: "P1",
                label: "P1",
                children: [
                    {
                    id: "M3",
                    label: "M3",
                    },
                    {
                    id: "M3+",
                    label: "M3+",
                    },
                    {
                    id: "M3++",
                    label: "M3++",
                    },
                    {
                    id: "M5",
                    label: "M5",
                    },
                    {
                    id: "M6",
                    label: "M6",
                    },
                ],
                },
                {
                id: "P2",
                label: "P2",
                children: [
                    {
                    id: "M4",
                    label: "M4",
                    },
                    {
                    id: "M2",
                    label: "M2",
                    },
                ],
                },
                {
                id: "P3",
                label: "P3",
                children: [
                    {
                    id: "M4-",
                    label: "M4-",
                    },
                    {
                    id: "M1",
                    label: "M1",
                    },
                ],
                },
                {
                id: "ENROLLED",
                label: "ENROLLED",
                children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ]
                },
                {
                id: "Dnd",
                label: "Dnd",
                children: [
                    {
                    id: "N/A",
                    label: "N/A",
                    },
                    {
                    id: "DND",
                    label: "DND",
                    },
                ]
                }
            ],
            },
        ],
        macc_levels: [
        {
          id: "All",
          label: "All",
          children: [
            // { id: "blank", label: "No U Level" },
            { id: "U0--", label: "U0--" },
            { id: "U0", label: "U0" },
            { id: "U1", label: "U1" },
            { id: "U1+", label: "U1+" },
            { id: "U2", label: "U2" },
            { id: "U3-", label: "U3-" },
            { id: "U3", label: "U3" },
            { id: "U3+", label: "U3+" },
            { id: "U3++", label: "U3++"},
            { id: "U4", label: "U4" },
            { id: "U5", label: "U5" },
            { id: "U6", label: "U6" },
            { id: "U7-", label: "U7-" },
            { id: "U7", label: "U7" },
            { id: "U7R", label: "U7R" },
            { id: "U7+", label: "U7+" },
            { id: "U8", label: "U8" },
            { id: "U8+", label: "U8+" },
            { id: "U9-", label: "U9-" },
            { id: "U9", label: "U9" },
            { id: "U9+", label: "U9+" },
            { id: "U10-", label: "U10-" },
            { id: "U10", label: "U10" },
            { id: "U11", label: "U11" },
            { id: "U11+", label: "U11+" },
            { id: "U12", label: "U12" },
          ]
        }
      ],
        treeDataAttended: [
            {
                id: "All",
                label: "All",
                children: [
                    {
                        id: 'Yes',
                        label: "Yes",
                    },
                    {
                        id: 'No',
                        label: "No",
                    },
                ]
            }],
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'This month': [moment().startOf('month'), moment().endOf('month')],
            'This year': [moment().startOf('year'), moment().endOf('year')],
            'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
            'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 10 Days': [moment().subtract(10, 'days'), moment().subtract(1, 'days')],
            'Last 15 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
            'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
            'Last 60 Days': [moment().subtract(60, 'days'), moment().subtract(1, 'days')],
            'Last 90 Days': [moment().subtract(90, 'days'), moment().subtract(1, 'days')],
        },
        gm_city_stats: [],
        session_city_stats: [],
        clicked_row: {},
        city_calssification: null,
        session_city: null,
        user_with_gms: "",
        loggedin_team: "",
        login_spoc_id:"",
        disabled: false,
        table_columns: ['total', 'total_enrolled', 'total_non_enrolled', 'lms_total_enrolled', 'mf_total_enrolled', 'website_total_enrolled', 'whatsApp_total_enrolled', 'google_total_enrolled', 'superbot_total_enrolled', 'others_total_enrolled', 'lms_total_non_enrolled', 'mf_total_non_enrolled', 'website_total_non_enrolled', 'whatsApp_total_non_enrolled', 'google_total_non_enrolled', 'superbot_total_non_enrolled', 'others_total_non_enrolled'],

    }),
    mounted() {
        this.login_spoc_id = localStorage.getItem("spoc_id");
        this.userWiseTeam();
        this.getUsersWithGMs();
        // this.mergeAllSearch();
        this.fetchWebinars();
    },
    watch: {
        selected_webinar(val) {
            console.log("selected_webinar", val);
            if (val == undefined) {
                this.remove_webinar = "";
                this.live_webinar_id = "";
            }else{
                this.live_webinar_id = val.webinar_id;
            }
            this.mergeAllSearch()
        },
        currentTablePage(val) {
            console.log(val);
            this.getTabulatorDeatils(this.clicked_row.row, this.clicked_row.val)
        },
        initSelectedTeams(val) {
            console.log("initSelectedTeams", val);
            // this.gm_city_stats = []
            // this.total_of_gm_stats = {}
            // this.tabulator_details = []
            this.treeDataSpoc =  [
                {
                id: "All",
                label: "All",
                children: [],
                },
            ],
            this.user_with_gms = [];
            if(this.initSelectedTeams.includes('CM')){
                this.user_with_gms = this.cm_users;
                this.getDropdownData();
            }
            if(this.initSelectedTeams.includes('SR')){
                this.user_with_gms = this.sr_users;
                this.getDropdownData();
            }
            if(!this.initSelectedTeams.includes('SR') && !this.initSelectedTeams.includes('CM')){
                this.user_with_gms = this.all_users;
                this.getDropdownData();
            }
            this.mergeAllSearch();
        },
        initSelectedSpoc: function (val) {
            console.log("initSelectedSpoc", val);
            this.mergeAllSearch();
            // this.getSpocIds()
            if((localStorage.getItem('team') == 'GM' || localStorage.getItem('team') == 'CM' || localStorage.getItem('team') == 'SR') && this.selected_spoc.length != 0){
                this.getStats();
            }
        },
        initSelectedLevels(val) {
            console.log("initSelectedLevels", val);
            this.mergeAllSearch();
        },
        init_selected_iiml_levels(val) {
            console.log("init_selected_iiml_levels", val);
            this.mergeAllSearch();
        },
        initSelectedCourses(val) {
            console.log("initSelectedCourses", val);
            this.mergeAllSearch();
        },
        initAttended(val) {
            console.log("initAttended", val);
            this.mergeAllSearch();
        },
        selected_date(val) {
            if (val != null) {
                this.registrationDateRange = {
                    startDate: null,
                    endDate: null,
                }
                this.selected_accelerator_date = null;
            }
        },
        selected_accelerator_date(val) {
            if (val != null) {
                this.registrationDateRange = {
                    startDate: null,
                    endDate: null,
                }
                this.selected_date = null;
            }
        },
        registrationDateRange(val) {
            if (val.startDate != null && val.endDate != null) {
                this.selected_accelerator_date = null;
                this.selected_date = null;
            }
        },
    },
    methods: {
        fetchWebinars() {
            this.treeDataTopics = []
            axios
                .get(`${constants.SERVER_API}getAcceleratorWebinarTopic`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                    }
                )
                .then((response) => {
                    console.log("pastWebinar", response.data);
                    const webinars = [];
                    response.data.forEach((w) => {
                        let webinar = w;
                        // webinar.title = `${moment
                        //     .unix(webinar.start_time_unix)
                        //     .format("DD-MMM-YYYY")} : ${w.topic}`;
                        webinar.title = `${w.live_webinar_topic}`;
                        // webinar.webinar_date = moment.unix(webinar.start_time_unix).format("DD-MMM-YYYY")
                        // webinar.webinar_date = w.start_date;
                        webinars.push(webinar); 
                    });
                    console.log("jfhgjfhgui", webinars);
                    this.webinars = [];
                    webinars.forEach((element) => {
                        // console.log("webinar dashboard", element);
                        let webinar_obj = {
                            // id: element.id,
                            label: element.title,
                            webinar_id: element.live_webinar_id,
                            // webinar_date: element.webinar_date
                        };
                        this.webinars.push(webinar_obj);
                    });
                    // this.webinars = webinars;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        userWiseTeam() {
        this.initSelectedTeams = [];
        this.loggedin_team = localStorage.getItem('team');
        if(this.loggedin_team == 'Admin'){
            this.disabled = false;
        } else {
            this.disabled = true;
        }
        if (this.loggedin_team === 'Admin') {
            this.initSelectedTeams.push('CM');
        }
        if (this.loggedin_team === 'CM') {
            this.initSelectedTeams.push('CM');
        } else if (this.loggedin_team === 'SR') {
            this.initSelectedTeams.push('SR');
        } else if (this.loggedin_team === 'ExEd') {
            this.initSelectedTeams.push('ExEd');
        } else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") == 'CM') {
            this.initSelectedTeams.push('CM');
        }
        else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") != 'CM') {
            this.initSelectedTeams.push('SR');
        }
        },
        getUsersWithGMs() {
            let url = `${constants.SERVER_API}getUsersWithGMs`;
            axios
                .get(url, {
                headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                let logged_in_user = localStorage.getItem('spoc_id')
                let team = localStorage.getItem('team')
                if(team != 'GM' && team != 'CM' && team != 'SR'){
                    this.getStats();
                }
                if (team == 'CM' || team == 'SR' || team == 'ExEd') {
                    response.data.forEach(ele => {
                    if (logged_in_user == ele.id) {
                        this.user_with_gms = []
                        this.user_with_gms.push(ele);
                        this.getDropdownData();
                    }
                    });
                } else if (localStorage.getItem('team') == 'GM') {
                    this.getGmReportingSpocs(response.data)
                } else {
                    this.sr_users = [];
                    this.cm_users = [];
                    this.all_users = [];
                    this.user_with_gms = [];
                    for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                        if(element.team == 'CM'){
                        this.cm_users.push(element);
                        }
                        if(element.team == 'SR'){
                        this.sr_users.push(element);
                        } 
                        this.all_users.push(element);
                        
                    }
                    this.user_with_gms = [];
                    if(this.initSelectedTeams.includes('CM')){
                    this.user_with_gms = this.cm_users;
                    this.getDropdownData();
                    }
                    if(this.initSelectedTeams.includes('SR')){
                    this.user_with_gms = this.sr_users;
                    this.getDropdownData();
                    }
                    if(!this.initSelectedTeams.includes('SR') && !this.initSelectedTeams.includes('CM')){
                    this.user_with_gms = this.all_users;
                    this.getDropdownData();
                    }
                }
                })
                .catch((error) => {
                this.handleError(error);
                }).finally(()=>{
                this.getSpocIds();
                })
        },
        getGmReportingSpocs(res) {
            let gm_id = localStorage.getItem("spoc_id");
            let ids = [];
            let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    for (let i = 0; i < response.data.spocs.length; i++) {
                        const element = response.data.spocs[i];
                        ids.push(element.id);
                    }
                    this.gm_ids = ids
                    this.user_with_gms = []
                    this.gm_ids.forEach(gm => {
                        res.forEach(ele => {
                            if (ele.id == gm) {
                                this.user_with_gms.push(ele);
                            }
                        });
                    });
                    this.getDropdownData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getDropdownData() {
            this.initSelectedSpoc = [];
            this.treeDataSpoc = [
                {
                id: "All",
                label: "All",
                children: [],
                },
            ]
            let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();

            uniqe_city.forEach(element => {
                
                let obj = {
                id: element,
                label: element, 
                children: []
                }
                
                
            
                console.log("ritika",this.treeDataSpoc[0].children)
               
                this.treeDataSpoc[0].children.push(obj);




            })
            this.user_with_gms.forEach((element) => {
                this.treeDataSpoc[0].children.forEach((city) => {
                if (element.city === city.label) {
                    let gm_set = new Set(city.children.map((item) => item.label));
                    if (!gm_set.has(element.last_name)) {
                        let name = element.first_name
                        let team = localStorage.getItem('team');
                      let login_spoc_id = localStorage.getItem("spoc_id");
                        if(login_spoc_id == 132 ||login_spoc_id == 937 || login_spoc_id == 39){
                            let spoc_obj = {
                            id: element.first_name + " " + element.last_name,
                            label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                        }else{
                        if(name.substring(0,1) != 'X'){
                            let spoc_obj = {
                                id: element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj.id)
                            }
                        }
                        }
                    }
                }
                })
            });
        },
        getSpocIds() {
            console.log("hh", this.user_with_gms);
            let spocs_ids = [];
            this.selected_spoc.forEach(ele => {
                this.user_with_gms.forEach(element => {
                    let spoc_name = `${element.first_name} ${element.last_name}`; // Added a space between first_name and last_name
                    if (spoc_name === ele) {
                        spocs_ids.push(element.id);
                    }
                });
            });
            spocs_ids = [...new Set(spocs_ids.map((data) => data))];
            console.log("idss", spocs_ids);
            this.spoc_ids_data = spocs_ids.join();
        },
        async getStats() {
            let logs = await this.getSpocIds();
            console.log(logs);
            this.tabulator_details = [];
            this.$vs.loading();
            let obj = {
                course: this.selected_courses.toString(),
                teams: this.selected_teams.toString(),
                spoc_ids: this.spoc_ids_data,
                m_level: this.selected_levels.toString(),
                u_level: this.selected_iiml_levels.toString(),
                attended: this.selected_attended.toString(),
                registration_from_date: "",
                registration_to_date: "",
                webinar_id: this.live_webinar_id,
            }
            if (
                this.registrationDateRange.startDate !== null &&
                this.registrationDateRange.endDate !== null
            ) {
                obj.registration_from_date = this.datatoTimestamp(this.registrationDateRange.startDate);
                obj.registration_to_date = this.datatoTimestamp(this.registrationDateRange.endDate);
            }
            if (this.selected_date != null) {
                obj['month'] = moment(this.selected_date).format("MM");
                obj['year'] = moment(this.selected_date).format("YYYY")
            }
            if (this.selected_accelerator_date != null) {
                obj['accelerator_month'] = moment(this.selected_accelerator_date).format("MM");
                obj['accelerator_year'] = moment(this.selected_accelerator_date).format("YYYY")
            }
            console.log("seleted filters", obj);
            let url = `${constants.SERVER_API}acceleratorSessionCounts`;
            axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
                .then((response) => {
                    console.log("response", response.data);
                    this.gm_city_stats = response.data.gm_city_stats.sort((a, b) => a.city.localeCompare(b.city));
                    this.session_city_stats = response.data.session_city_stats.sort((a, b) => a.city.localeCompare(b.city));
                    this.getGmStatsTotals(this.gm_city_stats);
                    this.getSessionCityStatsTotals(this.session_city_stats);
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.handleError(error)
                    this.$vs.loading.close();
                });
        },
        customFormatter(date) {
            return moment(date).format("MMM-YYYY");
        },
        clearDateFilter() {
            this.selected_date = null;
            this.selected_accelerator_date = null;
            this.registrationDateRange = {
                startDate: null,
                endDate: null,
            };
        },
        mergeAllSearch() {
            if (this.selected_webinar != null && this.selected_webinar != undefined) {
                this.remove_webinar = "";
                this.remove_webinar = this.selected_webinar.label;
            }
            this.selected_teams = [];
            this.initSelectedTeams.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataTeams[0].children.forEach((child) => {
                        this.selected_teams.push(child.label);
                    });
                } else {
                    this.selected_teams.push(sort);
                }
            });
            this.selected_spoc = [];
            this.initSelectedSpoc.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataSpoc[0].children.forEach((child) => {
                        this.selected_spoc.push(child.label);
                    
                    });
                } else {
                    this.selected_spoc.push(sort);
                }
            });
            this.selected_levels = [];
            this.initSelectedLevels.forEach((sort) => {
                if (sort === "All") {
                this.treeDataLevels[0].children[0].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                this.treeDataLevels[0].children[1].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                this.treeDataLevels[0].children[2].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                this.treeDataLevels[0].children[3].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                this.treeDataLevels[0].children[4].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                } else if (sort === "P1") {
                this.treeDataLevels[0].children[0].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                } else if (sort === "P2") {
                this.treeDataLevels[0].children[1].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                } else if (sort === "P3") {
                this.treeDataLevels[0].children[2].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                } else if (sort === "ENROLLED") {
                this.treeDataLevels[0].children[3].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                }
                else if (sort === "Dnd") {
                this.treeDataLevels[0].children[4].children.forEach((child) => {
                    this.selected_levels.push(child.label);
                });
                } else {
                console.log("sort", sort);
                this.selected_levels.push(sort);
                }
            });
            this.selected_iiml_levels = [];
            this.init_selected_iiml_levels.forEach((sort) => {
                if (sort === "All") {
                this.macc_levels[0].children.forEach((child) => {
                    this.selected_iiml_levels.push(child.label);
                });
                } else {
                this.selected_iiml_levels.push(sort);
                }
            });
            this.selected_courses = [];
            this.initSelectedCourses.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataCourses[0].children.forEach((child) => {
                        this.selected_courses.push(child.label);
                    });
                } else {
                    this.selected_courses.push(sort);
                }
            });
            this.selected_attended = [];
            this.initAttended.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataAttended[0].children.forEach((child) => {
                        this.selected_attended.push(child.label);
                    });
                } else {
                    this.selected_attended.push(sort);
                }
            });
        },
        removechip(chip, name) {
            if (name == "webinar") {
                this.selected_webinar = null;
                this.remove_webinar = "";
            }
            if (name == "team") {
                this.selected_teams = []
                if (this.initSelectedTeams.indexOf(chip) === -1) {
                    this.initSelectedTeams = [];
                    this.treeDataTeams[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedTeams.push(child.label);
                            this.selected_teams.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedTeams.splice(this.initSelectedTeams.indexOf(chip), 1);
                    this.selected_teams.splice(this.selected_teams.indexOf(chip), 1);
                }
            }
            if (name == "spoc") {
                this.selected_spoc = []
                if (this.initSelectedSpoc.indexOf(chip) === -1) {
                    this.initSelectedSpoc = [];
                    this.treeDataTeams[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedSpoc.push(child.label);
                            this.selected_spoc.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedSpoc.splice(this.initSelectedSpoc.indexOf(chip), 1);
                    this.selected_spoc.splice(this.selected_spoc.indexOf(chip), 1);
                }
            }
            if (name == "mlevel") {
                this.selected_levels = []
                if (this.initSelectedLevels.indexOf(chip) === -1) {
                    this.initSelectedLevels = [];
                    this.treeDataLevels[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedLevels.push(child.label);
                            this.selected_levels.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
                    this.selected_levels.splice(this.selected_levels.indexOf(chip), 1);
                }
            }
            if (name == "iiml_level") {
                this.selected_iiml_levels = []
                if (this.init_selected_iiml_levels.indexOf(chip) === -1) {
                    this.init_selected_iiml_levels = [];
                    this.macc_levels[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.init_selected_iiml_levels.push(child.label);
                            this.selected_iiml_levels.push(child.label);
                        }
                    });
                } else {
                    this.init_selected_iiml_levels.splice(this.init_selected_iiml_levels.indexOf(chip), 1);
                    this.selected_iiml_levels.splice(this.selected_iiml_levels.indexOf(chip), 1);
                }
            }
            if (name == "course") {
                this.selected_courses = []
                if (this.initSelectedCourses.indexOf(chip) === -1) {
                    this.initSelectedCourses = [];
                    this.treeDataCourses[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedCourses.push(child.label);
                            this.selected_courses.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
                    this.selected_courses.splice(this.selected_courses.indexOf(chip), 1);
                }
            }
            if (name == "attended") {
                this.selected_attended = []
                if (this.initAttended.indexOf(chip) === -1) {
                    this.initAttended = [];
                    this.treeDataAttended[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initAttended.push(child.label);
                            this.selected_attended.push(child.label);
                        }
                    });
                } else {
                    this.initAttended.splice(this.initAttended.indexOf(chip), 1);
                    this.selected_attended.splice(this.selected_attended.indexOf(chip), 1);
                }
            }
        },
        refreshBdeLeadsData() {
            this.selected_webinar = null;
            this.clicked_row = {};
            this.selected_date = null;
            this.selected_accelerator_date = null;
            this.registrationDateRange = {
                startDate: null,
                endDate: null,
            };
            this.selected_courses = [];
            this.initSelectedCourses = [];
            this.selected_teams = [];
            this.initSelectedTeams = [];
            this.selected_spoc = [];
            this.initSelectedLevels = [];
            this.selected_levels = [];
            this.initSelectedSpoc = [];
            this.spoc_ids_data = '';
            this.init_selected_iiml_levels = [];
            this.selected_iiml_levels = [];
            // this.selected_attended = [];
            this.initAttended = ['All'];
            this.userWiseTeam();
            this.getUsersWithGMs();
        },
        getGmStatsTotals(data) {
            this.total_of_gm_stats = {}
            data.forEach(element => {
                if (!this.total_of_gm_stats.hasOwnProperty('total') && element['total'] != undefined) {
                    this.total_of_gm_stats['total'] = element['total']
                }
                else if (element['total'] != undefined) {
                    this.total_of_gm_stats['total'] += element['total']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('total_enrolled') && element['total_enrolled'] != undefined) {
                    this.total_of_gm_stats['total_enrolled'] = element['total_enrolled']
                }
                else if (element['total_enrolled'] != undefined) {
                    this.total_of_gm_stats['total_enrolled'] += element['total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('total_non_enrolled') && element['total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['total_non_enrolled'] = element['total_non_enrolled']
                }
                else if (element['total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['total_non_enrolled'] += element['total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('lms_total_enrolled') && element['lms_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['lms_total_enrolled'] = element['lms_total_enrolled']
                }
                else if (element['lms_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['lms_total_enrolled'] += element['lms_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('mf_total_enrolled') && element['mf_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['mf_total_enrolled'] = element['mf_total_enrolled']
                }
                else if (element['mf_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['mf_total_enrolled'] += element['mf_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('website_total_enrolled') && element['website_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['website_total_enrolled'] = element['website_total_enrolled']
                }
                else if (element['website_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['website_total_enrolled'] += element['website_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('whatsApp_total_enrolled') && element['whatsApp_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['whatsApp_total_enrolled'] = element['whatsApp_total_enrolled']
                }
                else if (element['whatsApp_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['whatsApp_total_enrolled'] += element['whatsApp_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('google_total_enrolled') && element['google_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['google_total_enrolled'] = element['google_total_enrolled']
                }
                else if (element['google_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['google_total_enrolled'] += element['google_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('superbot_total_enrolled') && element['superbot_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['superbot_total_enrolled'] = element['superbot_total_enrolled']
                }
                else if (element['superbot_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['superbot_total_enrolled'] += element['superbot_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('others_total_enrolled') && element['others_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['others_total_enrolled'] = element['others_total_enrolled']
                }
                else if (element['others_total_enrolled'] != undefined) {
                    this.total_of_gm_stats['others_total_enrolled'] += element['others_total_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('lms_total_non_enrolled') && element['lms_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['lms_total_non_enrolled'] = element['lms_total_non_enrolled']
                }
                else if (element['lms_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['lms_total_non_enrolled'] += element['lms_total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('mf_total_non_enrolled') && element['mf_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['mf_total_non_enrolled'] = element['mf_total_non_enrolled']
                }
                else if (element['mf_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['mf_total_non_enrolled'] += element['mf_total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('website_total_non_enrolled') && element['website_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['website_total_non_enrolled'] = element['website_total_non_enrolled']
                }
                else if (element['website_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['website_total_non_enrolled'] += element['website_total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('whatsApp_total_non_enrolled') && element['whatsApp_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['whatsApp_total_non_enrolled'] = element['whatsApp_total_non_enrolled']
                }
                else if (element['whatsApp_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['whatsApp_total_non_enrolled'] += element['whatsApp_total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('google_total_non_enrolled') && element['google_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['google_total_non_enrolled'] = element['google_total_non_enrolled']
                }
                else if (element['google_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['google_total_non_enrolled'] += element['google_total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('superbot_total_non_enrolled') && element['superbot_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['superbot_total_non_enrolled'] = element['superbot_total_non_enrolled']
                }
                else if (element['superbot_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['superbot_total_non_enrolled'] += element['superbot_total_non_enrolled']
                }
                if (!this.total_of_gm_stats.hasOwnProperty('others_total_non_enrolled') && element['others_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['others_total_non_enrolled'] = element['others_total_non_enrolled']
                }
                else if (element['others_total_non_enrolled'] != undefined) {
                    this.total_of_gm_stats['others_total_non_enrolled'] += element['others_total_non_enrolled']
                }

            });
            console.log("totals", this.total_of_gm_stats);
        },
        getSessionCityStatsTotals(data) {
            this.total_of_session_city_stats = {}
            data.forEach(element => {
                if (!this.total_of_session_city_stats.hasOwnProperty('total') && element['total'] != undefined) {
                    this.total_of_session_city_stats['total'] = element['total']
                }
                else if (element['total'] != undefined) {
                    this.total_of_session_city_stats['total'] += element['total']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('total_enrolled') && element['total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['total_enrolled'] = element['total_enrolled']
                }
                else if (element['total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['total_enrolled'] += element['total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('total_non_enrolled') && element['total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['total_non_enrolled'] = element['total_non_enrolled']
                }
                else if (element['total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['total_non_enrolled'] += element['total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('lms_total_enrolled') && element['lms_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['lms_total_enrolled'] = element['lms_total_enrolled']
                }
                else if (element['lms_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['lms_total_enrolled'] += element['lms_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('mf_total_enrolled') && element['mf_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['mf_total_enrolled'] = element['mf_total_enrolled']
                }
                else if (element['mf_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['mf_total_enrolled'] += element['mf_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('website_total_enrolled') && element['website_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['website_total_enrolled'] = element['website_total_enrolled']
                }
                else if (element['website_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['website_total_enrolled'] += element['website_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('whatsApp_total_enrolled') && element['whatsApp_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['whatsApp_total_enrolled'] = element['whatsApp_total_enrolled']
                }
                else if (element['whatsApp_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['whatsApp_total_enrolled'] += element['whatsApp_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('google_total_enrolled') && element['google_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['google_total_enrolled'] = element['google_total_enrolled']
                }
                else if (element['google_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['google_total_enrolled'] += element['google_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('superbot_total_enrolled') && element['superbot_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['superbot_total_enrolled'] = element['superbot_total_enrolled']
                }
                else if (element['superbot_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['superbot_total_enrolled'] += element['superbot_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('others_total_enrolled') && element['others_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['others_total_enrolled'] = element['others_total_enrolled']
                }
                else if (element['others_total_enrolled'] != undefined) {
                    this.total_of_session_city_stats['others_total_enrolled'] += element['others_total_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('lms_total_non_enrolled') && element['lms_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['lms_total_non_enrolled'] = element['lms_total_non_enrolled']
                }
                else if (element['lms_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['lms_total_non_enrolled'] += element['lms_total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('mf_total_non_enrolled') && element['mf_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['mf_total_non_enrolled'] = element['mf_total_non_enrolled']
                }
                else if (element['mf_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['mf_total_non_enrolled'] += element['mf_total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('website_total_non_enrolled') && element['website_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['website_total_non_enrolled'] = element['website_total_non_enrolled']
                }
                else if (element['website_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['website_total_non_enrolled'] += element['website_total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('whatsApp_total_non_enrolled') && element['whatsApp_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['whatsApp_total_non_enrolled'] = element['whatsApp_total_non_enrolled']
                }
                else if (element['whatsApp_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['whatsApp_total_non_enrolled'] += element['whatsApp_total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('google_total_non_enrolled') && element['google_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['google_total_non_enrolled'] = element['google_total_non_enrolled']
                }
                else if (element['google_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['google_total_non_enrolled'] += element['google_total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('superbot_total_non_enrolled') && element['superbot_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['superbot_total_non_enrolled'] = element['superbot_total_non_enrolled']
                }
                else if (element['superbot_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['superbot_total_non_enrolled'] += element['superbot_total_non_enrolled']
                }
                if (!this.total_of_session_city_stats.hasOwnProperty('others_total_non_enrolled') && element['others_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['others_total_non_enrolled'] = element['others_total_non_enrolled']
                }
                else if (element['others_total_non_enrolled'] != undefined) {
                    this.total_of_session_city_stats['others_total_non_enrolled'] += element['others_total_non_enrolled']
                }

            });
            console.log("city totals", this.total_of_session_city_stats);
        },
        getTabulatorDeatilsPage(row, val){
            this.currentTablePage = 1
            this.getTabulatorDeatils(row, val)
        },
        getTabulatorDeatils(row, val){
            this.$vs.loading();
            let cities = ['AHMEDABAD', 'BANGALORE', 'CHENNAI', 'DELHI', 'HYDERABAD', 'KOLKATA', 'MUMBAI'];
            this.clicked_row['row'] = row
            this.clicked_row['val'] = val
            let obj = {
                course : this.selected_courses.toString(),
                teams : this.selected_teams.toString(),
                spoc_ids: this.spoc_ids_data,
                m_level: this.selected_levels.toString(),
                u_level: this.selected_iiml_levels.toString(),
                attended: this.selected_attended.toString(),
                enrolled_status_and_source : val,
                city_classification: '',
                session_city: '',
                registration_from_date: "",
                registration_to_date: "",
                webinar_id: this.live_webinar_id,
            }
            if (
                this.registrationDateRange.startDate !== null &&
                this.registrationDateRange.endDate !== null
            ) {
                obj.registration_from_date = this.datatoTimestamp(this.registrationDateRange.startDate);
                obj.registration_to_date = this.datatoTimestamp(this.registrationDateRange.endDate);
            }
            if (this.selected_date != null) {
                obj['month'] = moment(this.selected_date).format("MM");
                obj['year'] = moment(this.selected_date).format("YYYY")
            }
            if (this.selected_accelerator_date != null) {
                obj['accelerator_month'] = moment(this.selected_accelerator_date).format("MM");
                obj['accelerator_year'] = moment(this.selected_accelerator_date).format("YYYY")
            }
            if (cities.includes(row)) {
                obj.session_city = row;
            }else{
                obj.city_classification = row;
            }
            console.log("clicked data", obj);
            let url = `${constants.SERVER_API}acceleratorSessionCountStats?page=${this.currentTablePage}`;
            axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
                .then((response) => {
                    this.selected_stats = response.data.selected_stats;
                    this.tabulator_details = response.data.session_stats.data
                    this.currentTablePage = response.data.session_stats.current_page;
                    this.tablelinks = response.data.session_stats.last_page;
                    this.city_calssification = response.data.city_calssification;
                    this.session_city = response.data.session_city;
                    console.log("table data", response.data);
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.handleError(error)
                    this.$vs.loading.close();
                });

        },
        downloadReport() {  
           let obj = {
        team: this.selected_teams.toString(),
        spoc_id: this.spoc_ids_data,
        live_webinar_id:  this.live_webinar_id,
        registration_month: "",
        registration_year: "",
        registration_from_date: "",
        registration_to_date:"",
        accelerator_month: "",
        accelerator_year: "",
        course:  this.selected_courses.toString(),
        cm_level: this.selected_levels.toString() ,
        usp_level: this.selected_iiml_levels.toString() ,
        attended: this.selected_attended.toString(),
        user_email: localStorage.getItem("email_id"),
      };
      if (
        this.registrationDateRange.startDate !== null &&
        this.registrationDateRange.endDate !== null
      ) {
        obj.registration_from_date = this.datatoTimestamp(
          this.registrationDateRange.startDate
        );
        obj.registration_to_date = this.datatoTimestamp(
          this.registrationDateRange.endDate
        );
      }
      if (this.selected_date != null) {
        obj.registration_month = moment(this.selected_date).format("MM");
        obj.registration_year = moment(this.selected_date).format("YYYY");
      }
      if (this.selected_accelerator_date != null) {
        obj["accelerator_month"] = moment(
          this.selected_accelerator_date
        ).format("MM");
        obj["accelerator_year"] = moment(this.selected_accelerator_date).format(
          "YYYY"
        );
      }
      console.log("download", obj);
      let url = `https://mfcomms.2x2.ninja/api/acceleratorExportCsv`;
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.download_report_success_popup = true;
          console.log("All_Data", response.data);
          eventbus.$emit("close-download-popup");
          setTimeout(() => (this.download_report_success_popup = false), 4000);
        })
        .catch((error) => {
          if (error.response.status == 403) {
            this.$vs.notify({
              text: error.response.data.message,
              color: "danger",
            });
          } else {
            this.handleError(error);
          }
        });
    },
        
    }




}


</script>
<style lang="scss">
#stats_total {
  color: #0044BA;
}
.accelerator_search_container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100%;
    // min-height: 394.69px;
}

.enrollment_count {
    background: #0044ba;
    border-radius: 15px 0px;
    width: 260.62px;
    height: 37.8px;
    display: flex;
    justify-content: space-between;
    padding-inline: 1%;
    align-items: center;
}

.accelrator_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
}

.ele-side-head {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.filter {
    margin-left: 4px;
}

.tree-drop {
    margin-top: 0.8%;
}

.tree-drop#date>.my_date_picker>div>input {
    background: #fffbd5;
    border: 1px solid #e4d650;
    border-radius: 10px;
}

.tree-drop>.vs-con-dropdown {
    width: 90%;
    min-height: 32px;
    border-radius: 10px;
    // padding: 5.8px;
    padding-inline: 5px;
    // padding-top:3px;
    color: #000000;
    display: flex !important;
    align-items: center;
}

.tree-drop>.my_date_picker {
    width: 94%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
}

.ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
}

.accelrator-tree-drop>.vs-con-dropdown {
    width: 100%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
}

.accelrator-tree-drop#course>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #f38630;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.accelrator-tree-drop#team>.vs-con-dropdown {
    background: #caecc5;
    border: 1px solid #b5e1ae;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.accelrator-tree-drop#webinar>.vs-con-dropdown {
    background: #f5e1ce;
    border: 1px solid #f6cba8;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.accelrator-tree-drop#spoc>.vs-con-dropdown {
    background: #d0f1fb;
    border: 1px solid #92d8ee;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.accelrator-tree-drop#mlevel>.vs-con-dropdown {
    background: #fae1fe;
    border: 1px solid #eb98f8;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.accelrator-tree-drop#ulevel>.vs-con-dropdown {
    background: #edebf3;
    border: 1px solid #8977c0;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.accelrator-tree-drop#attended>.vs-con-dropdown {
    background: #eae5fa;
    border: 1px solid #b4a8db;
    border-radius: 10px;
    width: 100%;
    margin-top: 5%;
}

.none_padding {
    padding: 0px;
}

.criteria_chip {
    border-radius: 5px;
    margin-right: 1%;
}

.criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
}

.criteria_chip .vs-chip--text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #000000;
}

.criteria {
    background: #ffffff;
    min-height: 119.65px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.criteria_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.a-iconns>.material-icons {
    margin-left: auto;
}

.a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    font-size: 18px;
}

.a-iconns {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
    padding: 3%;
}

.vs-dropdown--menu {
    width: 300px;
}

.desc-border-box2 p {
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    padding-inline: 20px;
    align-items: center;
    background-color: #0044ba;
    border-radius: 0 0 15px 15px;
    width: 20%;
    height: 35px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.fixTableHead {
    overflow-y: auto;
    max-height: 350px;
}

.fixTableHead thead th {
    position: sticky;
    top: 0;
}

.rwd-table {
    height: 100%;
    margin: 10px auto;
    background-color: white;
    /* above is decorative or flexible */
    position: relative;
    /* could be absolute or relative */
    padding-top: 30px;
    /* height of header */


    width: 98%;
    font-family: 'Montserrat';
    margin: 25px auto;
    border-collapse: collapse;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10),
        0px 10px 20px rgba(0, 0, 0, 0.05),
        0px 20px 20px rgba(0, 0, 0, 0.05),
        0px 30px 20px rgba(0, 0, 0, 0.05);

    tr {
        &:hover {
            background: #f4f4f4;

            td {
                color: #555;
            }
        }
    }

    th,
    td {
        color: #000;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
        border-left: 1px solid rgba(0, 0, 0, 0.25);
        padding: 5px 14px;
        border-collapse: collapse;
    }

    th:first-child,
    td:first-child {
        border-left: 0px solid transparent !important;
    }

    th {
        background: #0044ba;
        color: #fff;
        font-size: 12px;

        &.last {
            border-right: none;
        }
    }

    tr:first-child th:first-child {
        border-top-left-radius: 10px;
    }

    tr:last-child th:last-child {
        border-top-right-radius: 10px;
    }

    tr:first-child td:last-child {
        border-top-right-radius: 10px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }
}

.rwd-table {
    tr td:last-child {
        font-size: 12px;
    }
}

.gm-stats-filter-card .vx-card__collapsible-content .vx-card__body {
    padding: 0px !important;
}

.gm-stats-filter-card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.date_filter_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.register_date>.reportrange-text {
    background: #ffffff;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
    min-height: 21.47px;
    border-radius: 10px;
}

.macc_date_picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 21.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}
</style>